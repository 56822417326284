var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      this.allowedRoles.some(function (role) {
        return this$1.$store.getters.getRole.includes(role)
      })
        ? _c(
            "v-container",
            {},
            [
              _c(
                "v-row",
                [_c("v-col", [_c("PageCompaniesListSubscription")], 1)],
                1
              ),
            ],
            1
          )
        : _c("v-container", [
            _c(
              "div",
              [
                _c(
                  "v-toolbar",
                  { attrs: { flat: "" } },
                  [
                    _c(
                      "v-tabs",
                      {
                        staticClass: "d-flex justify-center",
                        model: {
                          value: _vm.subscriptionTab,
                          callback: function ($$v) {
                            _vm.subscriptionTab = $$v
                          },
                          expression: "subscriptionTab",
                        },
                      },
                      [_c("v-tab", [_vm._v(" Active ")])],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-tabs-items",
                  {
                    model: {
                      value: _vm.subscriptionTab,
                      callback: function ($$v) {
                        _vm.subscriptionTab = $$v
                      },
                      expression: "subscriptionTab",
                    },
                  },
                  [_c("v-tab-item", [_c("PageCompaniesListSubscription")], 1)],
                  1
                ),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }